<template>
  <v-icon
    class="icon"
    @click="
      () => {
        download(Id);
      }
    "
    >mdi-arrow-down-bold</v-icon
  >
</template>

<script>
import axios from "axios";

export default {
  mounted() {},
  props: {
    Id: Number,
  },
  methods: {
    async download(id) {
      axios({
        url: `/attachment/${id}`, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `${response.headers["content-type"].split("/")[1]}`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
  },
};
</script>
<style scoped>
.icon {
  opacity: 0.5;
  color: green;
}
.icon:hover {
  opacity: 1;
}
</style>
