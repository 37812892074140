<template>
  <div>
    <footer :class="{ 'footerR': $i18n.locale == 'en', 'footerL': $i18n.locale != 'en' }">
      <Transition>
        <template v-if="exists1">
          <v-card>
            <div class="scroll-container">
              <div class="add-user__chips-wrapper">
                <v-card-text class="d-flex flex-column justify-center align-center">
                  <div v-for="(el, idx) in messageList" :key="idx" ref="contentDiv" class="add-user__chip">
                    <v-row md="4" style="font-weight: bold;">
                      <v-col md="1"></v-col>
                      <v-col md="4">{{ el.sender }}</v-col>
                      <v-col md="7">{{ el.date }}</v-col>
                    </v-row>
                    <v-row md="4">
                      <v-col md="2"></v-col>
                      <v-col md="10">
                        {{ el.message }}
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </div>
            </div>
            <v-row>
              <v-col md="1"></v-col>
              <v-col md="8" class="py-1">
                <v-text-field outlined @keydown.enter="sendMessages" v-model="message">
                </v-text-field>
              </v-col>
              <v-col md="1">
                <v-btn color="blue" class="circle-btn" @click="sendMessages">
                  <v-icon class="white--text" size="20">mdi-send</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </Transition>
      <v-row>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col md="5"></v-col>
        <v-col md="3">
        </v-col>
        <v-col md="3" style="height: 100px;">
          <button class="button button5" @click="showMessages"><span class="mdi mdi-chat"></span></button>
          <!-- <v-btn
                color="green"
                class="button button5"
                
              >
                <v-icon class="white--text" size="20">mdi-chat</v-icon>
               
              </v-btn> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
      </v-row>

    </footer>

  </div>
</template>
  
<script>
import axios from "axios";

import i18n from '@/i18n';
import { keys } from "mobx";


export default {
  name: 'Footer',
  props: {
    path: String,
  },
  data() {
    return {

      id: this.$route.params.id,
      exists: false,
      exists1: false,

      message: null,
      messageList: [],
      attachmentList: [],
    }
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      this.$refs.contentDiv.scrollTop = this.$refs.contentDiv.scrollHeight;

    },
    showAttachment() {
      this.exists = !this.exists;
      if (this.exists == true) {

      }
    },
    async showMessages() {
      this.exists1 = !this.exists1;
      if (this.exists1 == true) {
        const res = await axios.get(`message/${this.path}/${this.id}`);
        this.messageList = res.data.data;
      }

    },
    async sendMessages() {
      let params = {
        message: this.message,
        model_id: this.id,
        model_type: this.path
      }
      const res = await axios.post(`message/${this.path}`, params);
      this.message = null;
      const resp = await axios.get(`message/${this.path}/${this.id}`);
      this.messageList = resp.data.data;
      this.scrollToBottom();
    },
  },
  created() {
    this.scrollToBottom();
  },

}
</script>
  
<style>
.v-enter-active {
  /* background-color: lightblue; */
  animation: added 1s;
}

.v-leave-active {
  /* background-color: lightcoral; */
  animation: added 0.5s reverse;
}

@keyframes added {
  from {
    opacity: 0;
    translate: 0 100px;
  }

  to {
    opacity: 1;
    translate: 0 0;
  }
}

.circle-btn {
  border-radius: 50%;
  height: 50px;
  width: 25px;
  padding: 40px;
  margin: 4px 4px;
  cursor: pointer;


}

.customD {
  position: fixed;
}

.footerL {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  /* Add any additional styling as needed */
}

.footerR {
  position: fixed;
  bottom: 0;
  right: 0 !important;
  width: 30%;
  /* Add any additional styling as needed */
}

.scroll-container {
  transform: scaleY(-1);
  border: 0px;
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;
}

.add-user__chips-wrapper {
  /* max-height: 500px; */

  transform: scaleY(-1);

}

.add-user__chip {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
  /* padding: 5px 0;
  
  height: 70%;
  display: flex; */
  /* justify-content: space-around; */
  /* align-items: center; */
}

.button {
  background-color: rgb(68, 177, 68);
  border: none;
  color: white;
  height: 65px;
  width: 80%;
  padding: 10px;
  text-align: top;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  margin: 4px 4px;
  cursor: pointer;
}

.button5 {
  border-radius: 50%;
}
</style>
  


  <!-- 
        <v-card >
                  <div class="scroll-container">
                    <div class="add-user__-wrapper">
                      <v-card-text class="d-flex flex-column justify-center align-center">
                        <v-row  v-for="(el) in messageList"  ref="contentDiv" class="add-user__chip1">
                        <v-row >
                          <v-col md="1"></v-col>
                            <v-col md="4" >{{ el.sender }}</v-col>
                            <v-col md="7">{{ el.date }}</v-col>
                        </v-row>
        
                            <v-row >
                              <v-col md="1" ></v-col>
                            <v-col md="7">
                              {{ el.message }}
                            </v-col>
                            </v-row>
                          </v-row>
                      </v-card-text>
                    </div>
                  </div>
                <v-row >
                        <v-col md="1"></v-col>
                        <v-col md="8" class="py-1">
                        <v-text-field outlined @keydown.enter="sendMessages" v-model="message">

                        </v-text-field>
                        </v-col>
                        <v-col md="1" >
                          <v-btn
                          color="blue"
                          class="circle-btn"
                          @click="sendMessages"
                        >
                          <v-icon class="white--text" size="20">mdi-send</v-icon>
                        
                        </v-btn>
                        </v-col>
                       
                      </v-row>
                </v-card>
                
   -->